import {Route, Routes} from "react-router-dom";
import {
  PUBLIC_CALENDAR,
  PUBLIC_HOME_PAGE,
  PUBLIC_LEADERBOARDS,
  PUBLIC_PRICE_LIST,
  PUBLIC_SIGN_IN
} from "../../../constants/paths";
import HomePageRoute from "./pages/HomePageRoute";
import PriceListRoute from "./pages/PriceListRoute";
import SignInRoute from "./pages/SignInRoute";
import LeaderboardsRoute from "./pages/LeaderboardsRoute";
import CalendarRoute from "./pages/CalendarRoute";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={PUBLIC_HOME_PAGE} element={<HomePageRoute />} />
            <Route path={PUBLIC_PRICE_LIST} element={<PriceListRoute />} />
            <Route path={PUBLIC_SIGN_IN} element={<SignInRoute />} />
            <Route path={PUBLIC_LEADERBOARDS} element={<LeaderboardsRoute />} />
            <Route path={PUBLIC_CALENDAR} element={<CalendarRoute />} />
        </Routes>
    )
}

export default PublicRoutes;